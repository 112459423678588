.toggle-container {
    width: 42px;
    background-color: transparent;
    border: 3px solid #38d68b;
    cursor: pointer;
    user-select: none;
    padding: 2px;
    height: 14px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items:center;
    border-radius: 35px;
    box-sizing: content-box;
  }
  
  .dialog-button {
    cursor: pointer;
    font-weight: 700;
    font-size: 11px;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
    background-color: #38d68b;
    border-radius: 25px;
    width: 21px;
    height: 16px;
    display: flex;
    justify-content: center;
    color: #ffffff;
    position: absolute;
    left: 23px;
    transition: all 0.3s ease;
  }
  
  .disabled {
    background-color: #38d68b;
    left: 2px;
  }
  
  .dialog-button-enabled {
    cursor: pointer;
    font-weight: 700;
    font-size: 11px;
    width: 21px;
    height: 16px;
    display: flex;
    justify-content: center;
    color: #38d68b;
    position: absolute;
    right: 25px;
    transition: all 0.01s ease;
    border-radius: 25px;
  }
  
  .enabled {
    background-color: transparent;
    right: 2px;
    color: #38d68b;
  }
  