.loader {
    height: 50px;
    width: 50px;
    background: transparent;
    border: 7px solid #38D68B;
    border-top: 7px solid transparent;
    border-radius: 50%;
    animation: loader 1s linear infinite;
}

@keyframes loader{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}